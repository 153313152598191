<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('elearning_tpm.training_calendar')}} {{$t('globalTrans.details')}}</h4>
            </template>
          <template v-slot:headerAction>
            <router-link to="other-training-calender-list" :class="'mr-2 btn btn-success text-light'">{{ $t('elearning_tpm.training_calendar') }} {{ $t('globalTrans.list') }}</router-link>
            <b-button variant="primary" @click="pdfExport" class="">
                <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                  <b-col>
                      <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="16" :office-id="detailsData.office_id">
                         {{ '' }}
                      </list-report-head>
                  </b-col>
              </b-row>
              <b-overlay :show="load">
                <b-row id="form">
                  <div class="col-md-12">
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tpm.training_calendar') + ' ' + $t('globalTrans.details')}}</legend>
                      <p style="margin-left: 17px; p-4">{{$t('elearning_config.fiscal_year')}} : {{ detailsData ? getFiscalYear(detailsData.fiscal_year_id) : '' }}</p>
                      <table class="table" style="width:100%">
                        <thead class="thead">
                          <tr>
                            <th style="width:20%; text-align: center">{{$t('elearning_tpm.training_quarter')}}</th>
                            <th style="width:20%; text-align: center">{{$t('external_research.activity')}}</th>
                            <th style="width:10%; text-align: center">{{$t('elearning_tpm.quarterly_goals')}}</th>
                            <th style="width:30%; text-align: center">{{ $t('elearning_tpm.possible_training_date') }}</th>
                            <th style="width:20%; text-align: center">{{ $t('elearning_tpm.upazila_venue') }}</th>
                          </tr>
                        </thead>
                        <tr v-for="(item, index) in detailsData.details" :key="index">
                          <td style="vertical-align: middle; text-align: center"> {{ getQuarterName(item.training_quarter_id) }} </td>
                          <td style="vertical-align: middle; text-align: center"> {{ getTrainingTypeName(item.training_type_id) }} </td>
                          <td style="vertical-align: middle; text-align: center"> {{ $n(item.quarterly_goals) }} {{ $t('admission_form.batch') }} </td>
                          <td style="text-align: center">
                            <div v-for="(item2, row) in item.dates" :key="row">
                                <p> {{ item2.start_date ? dateFormation(item2.start_date, item2.end_date) : '' }} </p>
                            </div>
                          </td>
                          <td style="text-align: center">
                            <div v-for="(item2, row) in item.dates" :key="row">
                                <p> {{ currentLocale === 'en' ? item2.venu_name : item2.venu_name_bn }} </p>
                            </div>
                          </td>

                        </tr>
                        <template v-if="detailsData.length === 0">
                          <tr>
                            <th colspan="6" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                          </tr>
                        </template>
                      </table>
                    </fieldset>
                  </div>
                </b-row>
              </b-overlay>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import { otherTrainingCalendarDetails } from '../../api/routes'

export default {
    components: {
        ListReportHead
    },
  created () {
    if (this.$route.query.id) {
      this.getDetailsData(this.$route.query.id)
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      detailsData: {
          fiscal_year_id: '0',
          details: [
            {
              training_quarter_id: 0,
              training_type_id: 0,
              quarterly_goals: 0,
              dates: [
                {
                    start_date: '',
                    end_date: '',
                    venu_name: '',
                    venu_name_bn: ''
                }
              ]
            }
          ]
        },
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      load: false,
      officeTypeList: [],
      courses: [],
      items: [],
      trainees: [],
      trainers: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: [],
      date: '',
      date_bn: ''
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    getOfficeName (id) {
        const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return office !== undefined ? office.text_bn : ''
        } else {
            return office !== undefined ? office.text_en : ''
        }
    },
    getTrainingTypeName (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getQuarterName (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    dateFormation (fromDate, toDate) {
        if (fromDate && toDate) {
            const startDate = fromDate.split('-')
            const endDate = toDate.split('-')
            let startMonthId = ''
            let endMonthId = ''
            startMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(startDate[1]))
            endMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(endDate[1]))
            if (startDate[1] === endDate[1]) {
                if (this.$i18n.locale === 'bn') {
                    return this.convertToBanglaNumbers(this.$n(startDate[2])) + ' - ' + this.convertToBanglaNumbers(this.$n(endDate[2])) + ' ' + startMonthId.text_bn + ', ' + this.convertToBanglaNumbers(startDate[0], { useGrouping: false })
                } else {
                    return startDate[2] + ' - ' + endDate[2] + ' ' + startMonthId.text_en + ', ' + startDate[0]
                }
            } else {
                if (this.$i18n.locale === 'bn') {
                    return this.convertToBanglaNumbers(startDate[2]) + ' ' + startMonthId.text_bn + ' ' + ' - ' + ' ' + this.convertToBanglaNumbers(endDate[2]) + ' ' + endMonthId.text_bn + ', ' + this.convertToBanglaNumbers(startDate[0], { useGrouping: false })
                } else {
                    return startDate[2] + ' ' + startMonthId.text_en + ' ' + ' - ' + ' ' + endDate[2] + ' ' + endMonthId.text_en + ', ' + startDate[0]
                }
            }
        }
    },
    convertToBanglaNumbers (input) {
      function toBanglaNumber (match) {
        const numberMap = {
          0: '০',
          1: '১',
          2: '২',
          3: '৩',
          4: '৪',
          5: '৫',
          6: '৬',
          7: '৭',
          8: '৮',
          9: '৯'
        }
        return match.split('').map(char => numberMap[char] || char).join('')
      }
      return input.replace(/[0-9]/g, toBanglaNumber)
    },
    async getDetailsData (detailID) {
      if (detailID) {
          const detailID = {
            id: this.$route.query.id
          }
        this.load = true
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, otherTrainingCalendarDetails, detailID)
        if (!result.success) {
          this.detailsData = []
        } else {
            this.detailsData = result.data
        }
        this.load = false
      }
    },
    ageCalculation (dateofBirth) {
      const today = new Date()
      const birthDate = new Date(dateofBirth)
      let age = today.getFullYear() - birthDate.getFullYear()
      const m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--
      }
      return age
    },
    getRoomRentList (typeId) {
      return this.$store.state.TrainingElearning.commonObj.roomRentList.filter(item => item.status === 1 && item.room_type_id === typeId)
    },
    getRoomList (typeId) {
      const roomlist = this.$store.state.TrainingElearning.commonObj.accomodationSetupList.filter(item => item.status === 1 && item.room_type_id === typeId)
      const tempData = roomlist.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.room_no, value: item.room_no }
        } else {
          return { text: item.room_no, value: item.room_no }
        }
      })
      return tempData
    },
    getFloorList (typeId) {
      const floorlist = this.$store.state.TrainingElearning.commonObj.accomodationSetupList.filter(item => item.status === 1 && item.room_type_id === typeId)
      const tempData = floorlist.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.floor_no, value: item.floor_no }
        } else {
          return { text: item.floor_no, value: item.floor_no }
        }
      })
      return tempData
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.type_id === typeId)
       }
       return trainingCategoryList
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tpm.training_calendar') + ' ' + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.detailsData, this)
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.category_id === categoryId)
      }
      return trainingTitleList
    },
    getCustomDataList (item) {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          if (typeof orgObj !== 'undefined') {
            this.courses.org = orgObj.text_en
            this.courses.org_bn = orgObj.text_bn
          } else {
            this.courses.org = ''
            this.courses.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          if (typeof officeObj !== 'undefined') {
            this.courses.office = officeObj.text_en
            this.courses.office_bn = officeObj.text_bn
          } else {
            this.courses.office = ''
            this.courses.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          if (typeof fiscalYearObj !== 'undefined') {
            this.courses.fiscal_year = fiscalYearObj.text_en
            this.courses.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            this.courses.fiscal_year = ''
            this.courses.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          if (typeof trainingTitleObj !== 'undefined') {
            this.courses.training_title = trainingTitleObj.text_en
            this.courses.training_title_bn = trainingTitleObj.text_bn
          } else {
            this.courses.training_title = ''
            this.courses.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          if (typeof OfficeTypeListObj !== 'undefined') {
            this.courses.office_type = OfficeTypeListObj.text_en
            this.courses.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            this.courses.office_type = ''
            this.courses.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          if (typeof trainingTypeObj !== 'undefined') {
            this.courses.training_type = trainingTypeObj.text_en
            this.courses.training_type_bn = trainingTypeObj.text_bn
          } else {
            this.courses.training_type = ''
            this.courses.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          if (typeof trainingCategoryObj !== 'undefined') {
            this.courses.training_category = trainingCategoryObj.text_en
            this.courses.training_category_bn = trainingCategoryObj.text_bn
          } else {
            this.courses.training_category = ''
            this.courses.training_category_bn = ''
          }
      },
      getFiscalYear (fisId) {
        const fisYear = this.$store.state.commonObj.fiscalYearList.find(item => item.value === parseInt(fisId))
        if (fisYear === undefined) return ''
        return this.$i18n.locale === 'bn' ? fisYear.text_bn : fisYear.text_en
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
